<template>
<div>
   <div class="text-center mt-3"><fa-icon :icon="['fad','digging']" size="5x"/></div>
  <h2 class="text-center mt-3">Dashboard page is in progress</h2>
</div>
 
</template>

<script>
export default {
  steup(){
  }
}
</script>

<style>

</style>